<template>
    <div>
      <div>
        <v-row align="center" class="pa-8" style="gap: 20px">
          <v-text-field v-model="dateRangeText" label="Date range" prepend-icon="mdi-calendar" @click="openDatePicker" readonly />
          <v-btn color="primary" @click="searchTransactions">Search</v-btn>
          <v-btn color="secondary" @click="clearDateRange">Clear</v-btn>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
              <v-date-picker v-model="dates" range :max="today" />
              <v-card-actions>
                <v-spacer />
                <v-btn color="error" text @click="clearDateRange">
                  Clear
                </v-btn>
                <v-btn color="primary" text @click="doneDateRange">
                  Done
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        
        <v-data-table :headers="headers" :items="userTransactions" hide-default-footer :loading="loading" :loading-text="message" :items-per-page="currentRows">
          <template v-slot:item.amount="{ item }">
            <v-title text :class="{ 'success-text-btn': parseInt(item.amount) > 0, 'error-text-btn': parseInt(item.amount) <= 0 }">
              {{ item.amount ??  "0" }}
            </v-title>
          </template>
          <template v-slot:item.created_at="{ item }">
            <v-title text>
              {{ formatDate(item.created_at) }}
            </v-title>
          </template>
        </v-data-table>
        <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
          :total-visible="7" />
      </v-col>
    </v-row>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'TransactionOutsideDateRange',
  data() {
    return {
      startDate: null,
      endDate: null,
      dialog: false,
      headers: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Address', value: 'address_1' },
        { text: 'Amount', value: 'amount' },
        { text: 'Created At', value: 'created_at' },
      ],
      userTransactions: [],
      message: 'Loading... Please wait',
      loading: false,
      currentRows: 10,
      dates: [],
      currentPage: 1,
    //   noOfPages: 1,
      rowsPerPage: [10, 15, 30, 50, 100],
    
      today: new Date().toISOString(),
      abortController: new AbortController()
    };
  },
  props: {
    currentServer: Object,
  },
  computed: {
    noOfPages() {
        // const maxPages = 40;
    return Math.min(Math.ceil(this.userTransactions.length / this.currentRows));
  },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    openDatePicker() {
      this.dialog = true;
    },
    handleChangeRows() {
      this.currentPage = 1
      this.fetchUserTransactionsOutsideDateRange(this.search);
    },
    pageChange() {
      this.fetchUserTransactionsOutsideDateRange(this.search);
    },
    formatDate(dateString) {
    // Implement your date formatting logic here
    // For example, you can use JavaScript Date API
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust the formatting as needed
  },
    formatCurrency(amount) {
    // Implement your currency formatting logic here
    // For example, you can use JavaScript Intl.NumberFormat API
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  },
    clearDateRange() {
      this.dates = [];
      this.startDate = null;
      this.endDate = null;
    },
    doneDateRange() {
      this.dialog = false;
      this.startDate = this.dates[0];
      this.endDate = this.dates[1];
    },
    async searchTransactions() {
      this.fetchUserTransactionsOutsideDateRange();
    },
    async fetchUserTransactionsOutsideDateRange() {
      if (this.startDate && this.endDate) {
        try {
          this.abortController.abort();
          this.abortController = new AbortController();
          const signal = this.abortController.signal;
          this.loading = true;
          // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
          const baseurl = this.currentServer?.baseUrl
          const response = await fetch(`${baseurl}/admin/reporting/user_transactions_outside_date_range?start_date=${this.startDate}&end_date=${this.endDate}`, {            method: 'GET',
            signal,
          });
          const data = await response.json();
          this.userTransactions = data;
          this.loading = false;
        } catch (error) {
          console.error('Error fetching data:', error);
          this.loading = false;
        }
      } else {
        console.warn('Please select both start and end dates.');
      }
    },
  },
}
</script>


<style scoped>
.search-button {
    padding: 12px 20px;

    background-color: #007bff;
  
    color: white;

    border: none;
  
    border-radius: 5px;
    
    cursor: pointer;

}


.date-picker {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    /* Adjust as needed */
}

.search-button {
    margin-top: 10px;
    /* Adjust as needed */
}
</style>